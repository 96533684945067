<template>
  <top-bar :title="state.title" back-to="/costs" />
  <component
    v-if="state.costs"
    class="pt-20 p-4 bg-gray-300 text-veturi-dark w-full h-full overflow-auto"
    :is="state.name"
    :name="state.name"
    :title="state.title"
    :costs="state.costs"
    @reload-costs="fetchCosts"
  />
  <div
    v-else
    class="pt-20 p-4 bg-gray-300 text-veturi-dark w-full h-full overflow-auto items-center justify-center flex"
  >
    <icon name="refresh" class="flex w-1/3 h-1/3 animate-spin spin" />
  </div>
  <component v-if="!state.title" :is="`not-found`" />
</template>

<script>
// import { computed, reactive, watch } from 'vue'
import { reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import TopBar from '../../components/TopBar'
import Icon from '../../components/Icon'
import NotFound from '../../views/NotFound'
import Models from './Models'
import Materials from './Materials'

export default {
  components: {
    TopBar,
    NotFound,
    Models,
    Materials,
    Icon
  },
  setup (props) {
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      name: route.params.name,
      costs: [],
      title: ''
    })

    async function fetchCosts () {
      state.costs = await store.dispatch('getCost', state.name)
    }

    onMounted(async () => {
      state.title = await store.dispatch('getCostTitle', state.name)
      await fetchCosts(state.name)
      document.title = `Veturi - ${state.title}`
    })

    return {
      state,
      fetchCosts
    }
  }

}
</script>

<style>
.spin {
  animation-duration: 2s;
}
</style>
