<template>
  <div :class="styles">
    <div class="models-grid-template grid gap-4">
      <div
        class="w-full h-full p-4 rounded-xl bg-veturi-main text-veturi-white"
        v-for="cost of costs"
        :key="cost.id"
      >
        <form @submit.prevent="handleSubmit" class="grid grid-flow-row">
          <div class="grid grid-cols-2 gap-4 items-center justify-center">
            <button
              @click="() => removeCost(cost.id)"
              class="action-button bg-veturi-error"
            >
              <span class="font-bold mr-2">Excluir</span>
              <icon name="trash" />
            </button>
            <button
              @click="() => saveCost(cost)"
              class="action-button bg-veturi-dark"
            >
              <span class="font-bold mr-2">Salvar</span>
              <icon name="save" />
            </button>
          </div>
          <div class="form-group grid">
            <label :for="`model-name-${cost.id}`">
              Modelo
            </label>
            <input
              :id="`model-name-${cost.id}`"
              type="text"
              v-model="cost.name"
            />
          </div>
          <fieldset class="form-group">
            <legend class="px-2">Custos</legend>
            <div class="grid grid-flow-col grid-cols-2 gap-4">
              <div class="grid">
                <label :for="`model-sewing-${cost.id}`">
                  Costura
                </label>
                <input
                  :id="`model-sewing-${cost.id}`"
                  type="text"
                  v-model="cost.sewing_cost"
                />
              </div>
              <div class="grid">
                <label :for="`model-collar-${cost.id}`">
                  Gola e punho
                </label>
                <input
                  :id="`model-collar-${cost.id}`"
                  type="text"
                  v-model="cost.collar_cuff_cost"
                />
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group">
            <legend class="px-2">Quantidade</legend>
            <div class="grid grid-flow-col grid-cols-2 gap-4">
              <div class="grid">
                <label :for="`model-q-min-${cost.id}`">
                  Mínima
                </label>
                <input
                  :id="`model-q-min-${cost.id}`"
                  type="text"
                  v-model="cost.quantity_min"
                />
              </div>
              <div class="grid">
                <label :for="`model-q-max-${cost.id}`">
                  Máxima
                </label>
                <input
                  :id="`model-q-max-${cost.id}`"
                  type="text"
                  v-model="cost.quantity_max"
                />
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group">
            <legend class="px-2">Lucro (%)</legend>
            <div class="grid grid-flow-col grid-cols-2 gap-4">
              <div class="grid">
                <label :for="`model-p-min-${cost.id}`">
                  Mínimo
                </label>
                <input
                  :id="`model-p-min-${cost.id}`"
                  type="text"
                  v-model="cost.profitrate_min"
                />
              </div>
              <div class="grid">
                <label :for="`model-p-max-${cost.id}`">
                  Máxima
                </label>
                <input
                  :id="`model-p-max-${cost.id}`"
                  type="text"
                  v-model="cost.profitrate_max"
                />
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div
        class="fixed bottom-4 right-4 bg-veturi-light text-veturi-white font-regular text-4xl h-12 w-12 flex items-center justify-center rounded-full cursor-pointer"
        @click="addCost"
      >+</div>
  </div>
</div>
</template>

<script>
import { computed, reactive } from 'vue'
import Icon from '../../components/Icon'
import { cost } from '../../services'

export default {
  props: {
    title: { type: String },
    name: { type: String },
    costs: { type: Array },
    class: { type: String }
  },

  components: { Icon },

  setup (props, { emit }) {
    const state = reactive({
      costs: []
    })

    state.costs = computed(() => {
      const temp = [...props.costs]
      // return temp.sort((a, b) => a.name > b.name ? 1 : -1)
      return temp
    })

    async function saveCost (costToSave) {
      await cost.updateCost(props.name, costToSave)
    }

    async function addCost () {
      const newCost = {
        name: 'Novo Modelo',
        sewing_cost: 0,
        collar_cuff_cost: 0,
        quantity_min: 0,
        quantity_max: 0,
        profitrate_min: 0,
        profitrate_max: 0
      }
      await cost.createCost(props.name, newCost)
      emit('reload-costs')
    }

    async function removeCost (idToRemove) {
      await cost.deleteCost(props.name, idToRemove)
      emit('reload-costs')
    }

    return {
      styles: props.class,
      state,
      saveCost,
      addCost,
      removeCost
    }
  }
}
</script>

<style scoped>
.models-grid-template {
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-template-rows: 1fr;
}

.action-button {
  @apply h-8 flex items-center justify-center rounded-full focus:outline-none;
}

input {
  @apply rounded-lg mt-1 w-full bg-veturi-white text-veturi-dark font-bold focus:border-veturi-accent border-veturi-white border-2;
}

.form-group {
  @apply mt-2;
}

fieldset {
  @apply form-group border-2 p-2 text-veturi-white border-veturi-white font-bold rounded-lg;
}
</style>
